<template>

    <!-- form -->
    <validation-observer
    ref="form"
    #default="{invalid}"
    >
      <b-form
      class="auth-register-form"
      @submit.prevent="setCompany"
      >
        <b-row>
          <b-col md="6">
            <b-form-group
            :label="$t('name_brand')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('name_brand')"
                rules="required"
              >
                <b-form-input
                  v-model="formData.name"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('name_brand')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
            :label="$t('size_company')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('size_company')"
                rules="required"
              >
                <b-form-select
                  v-model="formData.employees_number"
                  :options="$t('options')"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
            :label="$t('position')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('position')"
                vid="posicion"
                rules="required"
                tag="div"
              >
                <b-form-select
                  id="position"
                  v-model="formData.position"
                  :name="$t('position')"
                  :class="{'is-invalid': !!errors.length}"
                  :state="errors.length > 0 ? false:null"
                  :options="$t('positions')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
            :label="$t('telephone')"
            >
              <validation-provider
                :name="$t('telephone')"
                rules="required"
              >
                <input-tel-brandme
                  :number_dad="formData.telephone"
                  @number="updateTelephone"
                ></input-tel-brandme>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                :name="$t('telephone')"
              >
                <b-form-input ref="hidden_phone" v-model="formated_phone" style="height:0;width:0;overflow:hidden;padding: 0;border: 0;"></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="hasLetters" class="text-danger">
                {{$t('telephoneNoLetters')}}
              </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <hr>
            <b-button
              :disabled="clicked || invalid || !formData.state"
              variant="primary"
              block
              type="submit"
            >
              {{$t('register_company')}}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

</template>

<script>
import {
  BFormInput, BFormGroup, BRow, BCol, BFormSelect, BButton, BForm
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, length } from '@validations'
import service from '@/services/others'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { setuserData } from '@/libs/utils/user';
import abilities from '@/libs/acl/abilities';
import InputTelBrandme from '../components/inputs/InputTelBrandme.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
    InputTelBrandme
  },
  created () {
    this.setLoc()
    const self = this
    setTimeout(function() {
      self.$refs.hidden_phone.focus()
    }, 500)
  },
  computed: {
    hasLetters() {
      const number_format = this.formData.telephone.replace(/\s+/g, '');
      return /[a-zA-Z]/.test(number_format);
    }
  },
  data () {
    return {
      // ? Default locale for VeeValidate is 'en'
      setuserData,
      locale: 'es',
      clicked: false,
      formData: {
        name: '',
        employees_number: 1,
        position: '',
        telephone: '',
        country: '',
        state: false,
      },
      required,
      length,
      formated_phone: ''
    }
  },
  methods: {
    updateTelephone(telephone_data) {
      this.formData.telephone = telephone_data.number;
      this.formData.state = telephone_data.state;
      this.formData.country = telephone_data.country;
    },
    setLoc () {
      // switch the locale.
      this.locale = this.locale === 'en' ? 'es' : 'es'
      localize(this.locale)

      // re-validate to re-generate the messages.
    },
    setCompany () {
      this.clicked = true;
      this.$refs.form.validate()
        .then(valid => {
          if (valid) {
            service.createCompany(this.formData).then(response => {
              const readAbility = abilities.getAbilitie(response.user)
              setuserData(response, readAbility);
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  position: 'top-right',
                  props: {
                    title: this.$t('register.saved'),
                    icon: 'AlertCircleIcon',
                    variant: 'success',
                    text: this.$t('register.saved')
                  }
                })
              })
              this.$emit('next')
            })
              .catch(() => {
                loadToastificationContent().then((component) => {
                  this.$toast({
                    component: component.default,
                    position: 'top-right',
                    props: {
                      title: this.$t('register.errorSave'),
                      icon: 'AlertIcon',
                      variant: 'danger',
                      text: this.$t('register.textError')
                    }
                  })
                })
              })
          } else this.clicked = false;
        })
    },
  }
}
</script>

<style>
  .app-content {
    padding-top: calc(10rem) !important;
  }
</style>
